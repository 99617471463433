import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { Mutation, MutationCartAttributesUpdateArgs } from '@ts/shopify-storefront-api';
import { cartAttributesUpdateMutation, fetchStorefrontApi, throwCartErrors, useCart, useCartId } from '@services/shopify';

const useCartAttributesUpdate = (): UseMutationResult => {
	const queryClient = useQueryClient();
	const { data: cart } = useCart();
	const isUpsellsInCheckout = useFeatureIsOn('is-upsells-in-checkout');

	return useMutation(
		['cart', 'attributesUpdate', { cartId: cart?.id }],
		async ({ attributes }: MutationCartAttributesUpdateArgs) => {
			if (!cart?.id) return;
			const newAttributes = [
				...(cart?.attributes || []),
				...attributes,
				...[{ key: 'showCartAccesories', value: `${isUpsellsInCheckout}` }],
			];
			const { cartAttributesUpdate }: Mutation = await fetchStorefrontApi(cartAttributesUpdateMutation, {
				variables: { cartId: cart?.id, attributes: newAttributes },
			});
			const { userErrors } = cartAttributesUpdate;

			throwCartErrors(userErrors);
			queryClient.invalidateQueries(['cart', { cartId: cart?.id }]);
		},
		{
			onError: error => {
				console.error(`Error updating attributes in cart ${cart?.id}: ${error}`);
				queryClient.invalidateQueries(['cart', cart?.id]);
			},
		}
	);
};

export { useCartAttributesUpdate };
