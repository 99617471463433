import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Text } from '@contentful/rich-text-types';
import { ComponentButtonGrouping, ComponentText, ContentfulButtonFields, ContentfulImage, MarketingCardFields } from '@ts/index';
import { ButtonGrouping, ContentfulButton, ContentfulRichTextRenderer } from '@components';
import Flex from '../Flex';
import InteractiveCard from '../InteractiveCard';
import styles from './PromotionBanner.module.scss';

type PromotionBannerProps = {
	textContent: ComponentText;
	backgroundImage: ContentfulImage;
	button: ContentfulButtonFields;
	buttonGroup: ComponentButtonGrouping;
	marketingBlock: MarketingCardFields;
};

const PromotionBanner = ({ textContent, backgroundImage, button, buttonGroup, marketingBlock }: PromotionBannerProps) => {
	return (
		<div className={styles.container} style={backgroundImage && { backgroundImage: `url(${backgroundImage.url})` }}>
			<Flex column gap={4}>
				{textContent && ContentfulRichTextRenderer(false, textContent)}
				{button && <ContentfulButton {...button} />}
				{buttonGroup && <ButtonGrouping {...buttonGroup} />}
			</Flex>
			{marketingBlock && (
				<InteractiveCard
					background={marketingBlock.background.url}
					imgAlt={marketingBlock.background.title}
					title={marketingBlock.title}
					text={!!marketingBlock.text ? (marketingBlock.text?.content[0].content[0] as Text).value : null}
					subtitle={marketingBlock.subtitle}
					subheading={
						!marketingBlock.shouldExpand && !!marketingBlock.text
							? (marketingBlock.text?.content[0].content[0] as Text).value
							: ''
					}
					shouldExpand={marketingBlock.shouldExpand}
					button1={{
						label: marketingBlock.buttonLabel1,
						link: marketingBlock.buttonLink1,
						color: marketingBlock.buttonColor1,
					}}
					{...(marketingBlock.buttonLabel2 && {
						button2: {
							label: marketingBlock.buttonLabel2,
							link: marketingBlock.buttonLink2,
							color: marketingBlock.buttonColor2,
						},
					})}
				/>
			)}
		</div>
	);
};

export default PromotionBanner;
