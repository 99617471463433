import React from 'react';
import { useRouter } from 'next/router';
import variables from '@styles/export.module.scss';
import { Flex, Lozenge, Paragraph } from '@components';
import { formatCurrency } from '@utils/shopify';
import { BADGE_TYPES, LOCALE_CODES } from '@constants';
import { capitalizeEachWord } from '@utils/strings';
import styles from './CompareAtPrice.module.scss';

type CompareAtPriceProps = {
	price: { amount: number; currencyCode: string };
	compareAtPrice: { amount: number; currencyCode: string } | null;
	useLabel?: boolean;
	tag?: typeof BADGE_TYPES.TOP_RATED;
};

const CompareAtPrice = ({ price, compareAtPrice, useLabel = false, tag }: CompareAtPriceProps) => {
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;

	return (
		<Flex gap={2} align='center'>
			<Paragraph style={{ fontSize: '1.6rem' }}>
				{useLabel && <span style={{ color: variables.gray4, fontSize: '1.6rem' }}>{`Starting at `}</span>}
				<span style={{ color: compareAtPrice ? variables.red1 : variables.gray4 }}>
					{formatCurrency(
						{
							...price,
							locale,
						},
						showCurr
					)}
				</span>
			</Paragraph>
			{compareAtPrice && (
				<Paragraph style={{ fontSize: '1.6rem', color: variables.gray3, textDecoration: 'line-through' }}>
					{`${formatCurrency({ ...compareAtPrice, locale }, showCurr)}`}
				</Paragraph>
			)}
			{tag && (
				<Lozenge key={`${tag.name}`} {...tag} shape='square' extraClasses={styles.tag}>
					{capitalizeEachWord(tag.displayText)}
				</Lozenge>
			)}
		</Flex>
	);
};

export default CompareAtPrice;
