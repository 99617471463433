import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Accordian, Flex } from '@components/common';
import { useBFContext } from '@context';
import { CustomLenses, LabelText, NavItemTypography } from '@components';
import variables from '@styles/export.module.scss';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import { BUILD_FLOW_STEPS } from '@constants';
import Prescriptions from '../Prescriptions';
import LensPackages from '../LensPackages';
import styles from './QuickAdd.module.scss';

const QuickAdd = () => {
	const { rxType, lensPackage, selectedLenses, setStep, readerRx } = useBFContext();
	const [isLensOpen, setIsLensOpen] = useState(true);
	const [isPackageOpen, setIsPackageOpen] = useState(false);
	const [isCustomizeOpen, setIsCustomizeOpen] = useState(false);
	const customizeStepRef = useRef<HTMLDivElement>(null);
	const { locale } = useRouter();
	const localizedRxType = locale === 'en-GB' && rxType === RX_TYPE.PROGRESSIVE ? 'Varifocal' : rxType;

	const handleLensOnClick = () => {
		setIsLensOpen(!isLensOpen);
		if (isPackageOpen) setIsPackageOpen(false);
		if (isCustomizeOpen) setIsCustomizeOpen(false);
	};

	const handlePackageOnClick = () => {
		setIsPackageOpen(!isPackageOpen);
		if (isLensOpen) setIsLensOpen(false);
		if (isCustomizeOpen) setIsCustomizeOpen(false);
	};

	const handleCustomizeOnClick = () => {
		setIsCustomizeOpen(!isCustomizeOpen);
		if (isLensOpen) setIsLensOpen(false);
		if (isPackageOpen) setIsPackageOpen(false);
		setStep(BUILD_FLOW_STEPS.CUSTOMIZE);
	};

	useEffect(() => {
		if (!!rxType && (rxType !== RX_TYPE.READERS || (rxType === RX_TYPE.READERS && !!readerRx))) {
			handlePackageOnClick();
		}
		if (!!lensPackage) {
			handleCustomizeOnClick();
		}
	}, [lensPackage, rxType, readerRx]);

	const parsedSelectedLenses = selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE)
		? 'BL + LR'
		: selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT)
			? 'BL'
			: null;

	return (
		<div className={styles.container}>
			{/* Rx */}
			<Accordian
				className={styles['accordian']}
				customRow={styles['row-override']}
				isOpen={isLensOpen}
				onClick={handleLensOnClick}
				iconStyle='caret'
				shouldRemoveBorder
				titleChildren={
					<Flex align='start' fullWidth gap={2}>
						<NavItemTypography shouldKeepTypography>Select Your Lenses</NavItemTypography>
						<LabelText>-</LabelText>
						<LabelText color={!!rxType ? variables.gray3 : variables.blue1}>
							{!!rxType ? localizedRxType : 'Required'}
						</LabelText>
					</Flex>
				}
			>
				<Flex column gap={3} className={styles['content']}>
					<Prescriptions />
				</Flex>
			</Accordian>
			{/* Lens Package */}
			<Accordian
				className={styles['accordian']}
				customRow={styles['row-override']}
				isOpen={isPackageOpen}
				onClick={handlePackageOnClick}
				iconStyle='caret'
				shouldRemoveBorder
				titleChildren={
					<Flex align='start' fullWidth gap={2}>
						<NavItemTypography shouldKeepTypography>Choose Your Lens Type</NavItemTypography>
						<LabelText>-</LabelText>
						<LabelText color={!!lensPackage ? variables.gray3 : variables.blue1}>
							{!!lensPackage ? lensPackage : 'Required'}
						</LabelText>
					</Flex>
				}
			>
				<Flex column className={styles['content']}>
					<LensPackages />
				</Flex>
			</Accordian>
			{/* Lens Add Ons */}
			<Accordian
				className={styles['accordian']}
				customRow={styles['row-override']}
				isOpen={isCustomizeOpen}
				onClick={handleCustomizeOnClick}
				shouldRemoveBorder
				iconStyle='caret'
				titleChildren={
					<Flex align='start' fullWidth gap={2}>
						<NavItemTypography shouldKeepTypography>Customize Your Lenses</NavItemTypography>
						<LabelText>-</LabelText>
						<LabelText color={variables.gray3}>
							{!!parsedSelectedLenses ? parsedSelectedLenses : 'Select Any'}
						</LabelText>
					</Flex>
				}
			>
				<Flex column className={styles['content']}>
					<CustomLenses ref={customizeStepRef} />
				</Flex>
			</Accordian>
		</div>
	);
};

export default QuickAdd;
