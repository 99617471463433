import { UPSELL_STATE } from '@utils/context/Order';

export const PAIR_CARE_MODAL_COPY = {
	[UPSELL_STATE.AVAILABLE]: {
		LINE_1: "By applying PairCare to your order, you're taking the right steps to protect your Base Frame purchase.",
		LINE_2: "PairCare is a one-year warranty that covers Base Frames, lenses, and lens add-ons in the event your Pair is scratched or damaged within one year from delivery. PairCare does not include Top Frames and doesn't cover theft or loss of the item. For more information visit our FAQs.",
	},
	[UPSELL_STATE.INELIGABLE]: {
		LINE_1: "By applying PairCare to your order, you're taking the right steps to protect your Base Frame purchase.",
		LINE_2: "PairCare is a one-year warranty that covers Base Frames, lenses, and lens add-ons in the event your Pair is scratched or damaged within one year from delivery. PairCare does not include Top Frames and doesn't cover theft or loss of the item. For more information visit our FAQs.",
	},
	[UPSELL_STATE.PURCHASED]: {
		LINE_1: "By applying PairCare to your order, you're taking the right steps to protect your Base Frame purchase.",
		LINE_2: "PairCare is a one-year warranty that covers Base Frames, lenses, and lens add-ons in the event your Pair is scratched or damaged within one year from delivery. PairCare does not include Top Frames and doesn't cover theft or loss of the item. For more information visit our FAQs.",
	},
	[UPSELL_STATE.PENDING]: {
		LINE_1: 'Check your inbox for an email from us with payment details!',
		LINE_2: "If you're unable to find the invoice in your email or need additional assistance, please",
	},
};

export const PAIR_CARE_MODAL_BUTTON_COPY: Record<UPSELL_STATE, string> = {
	[UPSELL_STATE.INELIGABLE]: '+Add',
	[UPSELL_STATE.AVAILABLE]: '+Add',
	[UPSELL_STATE.PENDING]: 'Pending',
	[UPSELL_STATE.PURCHASED]: 'Added',
};

export const PAIR_CARE_UPSELL_COPY: Record<UPSELL_STATE, string> = {
	[UPSELL_STATE.INELIGABLE]: 'Unfortunately, your order is 30 days past the purchase date and you can no longer add PairCare.',
	[UPSELL_STATE.AVAILABLE]: "There's still time to add on PairCare – make sure to protect your Pair!",
	[UPSELL_STATE.PENDING]: "We're just waiting on payment before we can add PairCare to your order. Check your inbox!",
	[UPSELL_STATE.PURCHASED]: "No worries – you've already guaranteed the protection of your Pair!",
};

export const getPasswordErrorMessage = (length: number): string => {
	return `Please lengthen this text to 5 characters or more (you are currently using ${length} characters).`;
};
