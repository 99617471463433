/* eslint-disable max-lines */
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { A11y, Keyboard, Navigation, Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions } from 'swiper/types';
import { DEFAULT_SWIPER, MODEL_LIFESTYLE_IMAGE } from '@constants';
import { CarouselTags as Tags, Flex, Loading, NavButtons, SaveTag, Slide, Thumbnails, VideoSlide } from '@components';
import { CarouselProps } from '@ts/components';
import { generateTopFrameImages } from '@utils/images';
import styles from './Carousel.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

export const DEFAULT_SWIPER_PROPS = {
	...DEFAULT_SWIPER,
	pagination: {
		clickable: true,
		type: 'bullets',
		bulletElement: 'button',
		el: '.swiper-pagination',
		bulletClass: styles['swiper-pagination-bullet'],
		bulletActiveClass: styles['swiper-pagination-bullet-active'],
		lockClass: styles['swiper-pagination-lock'],
		clickableClass: styles['swiper-pagination-clickable'],
	} as PaginationOptions,
};

const Carousel = ({
	images,
	imageLoadingHandler,
	name,
	secondary,
	selectedImgIndex,
	setSelectedImgIndex,
	tags,
	type,
	video,
	isImageLoading = false,
	isSecondaryImageLoading = false,
	swiperOptions = DEFAULT_SWIPER_PROPS,
	hideThumbnail = false,
	className,
	mode = 'pdp',
	variant,
	topFrameOnModal,
}: CarouselProps) => {
	const nextElRef = useRef(null),
		prevElRef = useRef(null),
		sliderRef = useRef(null),
		loadingRef = useRef(null);

	const isTopFrameBundle = type.includes('BUNDLE');
	const customPagination = {
		...DEFAULT_SWIPER_PROPS,
		pagination: {
			...DEFAULT_SWIPER_PROPS.pagination,
			bulletClass: video ? styles['swiper-pagination-bullet-video'] : styles['swiper-pagination-bullet'],
			bulletActiveClass: video
				? styles['swiper-pagination-bullet-active-video']
				: styles['swiper-pagination-bullet-active'],
		},
	};
	const { topFrameVariant, copyTopFrameVariant } = generateTopFrameImages(secondary, isImageLoading, images, mode, type);

	const [isThumbsClicked, setIsThumbsClicked] = useState(false);
	const withThreeFourthImage = copyTopFrameVariant?.length === 5;
	const topFrameVariantWithLifestyle = [
		...copyTopFrameVariant,
		...(!isImageLoading && topFrameOnModal?.length > 0
			? [{ primary: topFrameOnModal[0]?.url, secondary: MODEL_LIFESTYLE_IMAGE }]
			: []),
	];

	const handleNavigation = useCallback(
		action => {
			if (!sliderRef.current) return;
			const swiperInstance = sliderRef.current.swiper;
			switch (action) {
				case 'prev':
					swiperInstance.slidePrev();
					break;
				case 'next':
					swiperInstance.slideNext();
					break;
				default:
					break;
			}
			isThumbsClicked && setIsThumbsClicked(!isThumbsClicked);
		},
		[isThumbsClicked, selectedImgIndex, setSelectedImgIndex, setIsThumbsClicked]
	);

	useEffect(() => {
		const handleKeyDown = ({ key }) => {
			if (key === 'ArrowRight') handleNavigation('next');
			if (key === 'ArrowLeft') handleNavigation('prev');
		};

		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleNavigation]);

	useEffect(() => {
		if (sliderRef.current && isThumbsClicked) {
			sliderRef.current.swiper.slideToLoop(selectedImgIndex);
		}
	}, [isThumbsClicked, selectedImgIndex, sliderRef]);

	const handleSlideChange = newIndex => {
		if (!isNaN(newIndex)) {
			setSelectedImgIndex(newIndex);
		} else {
			sliderRef.current.swiper.slideTo(selectedImgIndex);
		}
	};

	const isBFMode = mode === 'buildflow';

	const videoSlide = video && (
		<SwiperSlide tag='li' zoom={swiperOptions.zoom} key='video-slide' className={styles['swiper-slide']}>
			<VideoSlide video={video} type={type} name={name} />
		</SwiperSlide>
	);

	const mappedTopFrameSlides = useCallback(() => {
		const classes = cn(styles['swiper-slide'], {
			[styles['with-3-4-image']]: withThreeFourthImage && !isBFMode,
			[styles['without-3-4-image']]: !withThreeFourthImage && !isBFMode,
			[styles['buildflow-images--with-3-imgs']]: isBFMode && topFrameVariant?.length < 2,
			[styles['buildflow-images--with-5-imgs']]: isBFMode && topFrameVariant?.length >= 2,
		});
		const slides =
			topFrameVariantWithLifestyle?.map(({ primary, secondary }, index) => {
				return (
					<SwiperSlide tag='li' zoom={swiperOptions.zoom} key={`image-slide-${index}`} className={classes}>
						<Slide
							index={index}
							imageLoadingHandler={imageLoadingHandler}
							name={name}
							primary={primary}
							secondary={secondary}
							type={primary ? type : 'LIFESTYLE'}
							key={`image-slide-${index}`}
						/>
					</SwiperSlide>
				);
			}) || [];
		return slides.concat(videoSlide);
	}, [
		topFrameVariantWithLifestyle,
		imageLoadingHandler,
		isBFMode,
		name,
		swiperOptions,
		topFrameVariant,
		type,
		withThreeFourthImage,
	]);

	const mappedSwiperSlides = useCallback(() => {
		let fixedImages = [...images];

		if (isBFMode) {
			fixedImages = [...images, images[0], images[1], images[0]];
		}

		const slides =
			fixedImages?.map(({ url, width = 1200, height = 600 }, index) => {
				return (
					<SwiperSlide
						tag='li'
						zoom={swiperOptions.zoom}
						key={`image-slide-${index}`}
						className={cn(styles['swiper-slide'], {
							[styles['buildflow-images--with-5-imgs']]: isBFMode,
						})}
					>
						<Slide
							index={index}
							type={type}
							name={name}
							imageLoadingHandler={imageLoadingHandler}
							url={url}
							height={height}
							width={width}
							key={`image-slide-${index}`}
						/>
					</SwiperSlide>
				);
			}) || [];
		return slides.concat(videoSlide);
	}, [imageLoadingHandler, images, isBFMode, name, secondary, swiperOptions, topFrameVariant, type, withThreeFourthImage]);

	return (
		<Flex maxWidth className={cn(styles['carousel-container'], className)}>
			<Thumbnails
				images={images}
				selectedImgIndex={selectedImgIndex}
				setSelectedImgIndex={setSelectedImgIndex}
				name={name}
				type={type}
				withThreeFourthImage={withThreeFourthImage}
				copyTopFrameVariant={topFrameVariantWithLifestyle}
				hideThumbnail={hideThumbnail}
				imageLoadingHandler={imageLoadingHandler}
				isThumbsClicked={isThumbsClicked}
				secondary={secondary}
				setIsThumbsClicked={setIsThumbsClicked}
				video={video}
			/>
			<Swiper
				ref={sliderRef}
				autoHeight={true}
				className={styles['container']}
				modules={[A11y, Keyboard, Navigation, Pagination, Zoom]}
				navigation={{ prevEl: prevElRef.current, nextEl: nextElRef.current }}
				a11y={swiperOptions.a11y}
				keyboard={swiperOptions.keyboard}
				pagination={customPagination.pagination}
				data-type={type}
				{...customPagination}
				onSlideChange={swiper => handleSlideChange(swiper.realIndex)}
				initialSlide={selectedImgIndex}
			>
				{secondary ? mappedTopFrameSlides() : mappedSwiperSlides()}
				{isImageLoading || (isSecondaryImageLoading && <Loading ref={loadingRef} className={styles['image-loader']} />)}
				<div className={cn('swiper-pagination', styles['swiper-pagination'])} />
				{!isTopFrameBundle && (
					<NavButtons
						handleNavigation={handleNavigation}
						images={images}
						type={type}
						prevElRef={prevElRef}
						nextElRef={nextElRef}
					/>
				)}
				{variant?.metafields?.discountTag && (
					<div className={styles.saveTag}>
						<SaveTag label='logo-pair-eyewear-color' detail={variant.metafields?.discountTag} />
					</div>
				)}
				<Tags tags={variant?.tags && variant?.tags.length > 0 ? variant.tags : tags} />
			</Swiper>
		</Flex>
	);
};

export default memo(Carousel);
