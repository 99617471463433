import { forwardRef, useMemo } from 'react';
import { SEO, SidebarLayout } from '@components';
import { ComponentProps } from '@ts/components';

type ProductViewRootProps = ComponentProps<HTMLDivElement> & {
	seo: Parameters<typeof SEO>[0];
};

const Root = forwardRef<HTMLDivElement, ProductViewRootProps>(
	({ children, seo: { product, title, description }, ...rest }, forwardedRef) => {
		const memoizedSEO = useMemo(
			() => (
				<SEO
					title={title}
					description={description}
					product={product}
					openGraph={{
						title: product?.name,
						description: product?.description,
						images: [
							{
								url: product?.images[0]?.url,
								width: product?.images[0]?.width.toString(),
								height: product?.images[0]?.height.toString(),
								alt: product?.name,
							},
						],
					}}
				/>
			),
			[product]
		);
		return (
			<SidebarLayout.Root ref={forwardedRef} pdpOptimizationStyle {...rest}>
				{memoizedSEO}
				{children}
			</SidebarLayout.Root>
		);
	}
);

Root.displayName = 'Root';

export default Root;
