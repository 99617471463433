import { useEffect, useState } from 'react';
import cn from 'classnames';
import { m } from 'framer-motion';
import { Detail, Minus, Plus } from '@components';
import { useElementHeight } from '@utils/hooks';
import { rotateVariants } from '@utils/motions';
import { isVideoUrl } from '@utils/urls';
import variables from '@styles/export.module.scss';
import { InteractiveCardProps } from '@ts/components';
import { InteractiveCardMedia } from './InteractiveCardMedia';
import { InteractiveCardCollapsedContent } from './InteractiveCardCollapsedContent';
import { InteractiveCardExpandedContent } from './InteractiveCardExpandedContent';

import styles from './InteractiveCard.module.scss';

const InteractiveCard = ({
	asHero = false,
	useBigHero = false,
	asPlp = false,
	asValueProp = false,
	background = null,
	backgroundMobile = null,
	bgColor = variables.blue3,
	button1,
	button2,
	buttonClassName = '',
	children,
	videoUrl,
	className = '',
	copyrightInfo,
	description,
	extraHeaderClassName = '',
	hideTitleWhenClosed = false,
	mediaClassName = '',
	solidBackgroundColor,
	subtitle,
	shouldExpand = true,
	subheading = '',
	shouldFixHeight = false,
	size = 'medium',
	shouldDisplayTitle = true,
	sourcesVideo = [],
	text = '',
	title = '',
	withBalancer = false,
	imgAlt,
	...rest
}: InteractiveCardProps) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [elementRef, elementHeight] = useElementHeight();
	const showTitle = hideTitleWhenClosed ? isExpanded : true,
		showVideo = isVideoUrl(background),
		containerClasses = cn(styles.container, {
			[className]: !!className,
			[styles['container--asHero']]: asHero,
			[styles['container--asHero--useBigHero']]: asHero && useBigHero,
			[styles['container--fixHeight']]: shouldFixHeight,
			[styles['container--asPlp']]: asPlp,
			[styles['container--asValueProp']]: asValueProp,
			[styles['container--micro']]: size === 'micro',
			[styles['container--flexible']]: size === 'flexible',
		});

	const handleClick = () => {
		if (shouldExpand) {
			setIsExpanded(!isExpanded);
		} else {
			if (button1 && button1.link) {
				window.location.href = button1.link;
			}
		}
	};

	useEffect(() => {
		if (isExpanded) {
			const timer = setTimeout(() => setIsExpanded(false), 10000);
			return () => clearTimeout(timer);
		}
	}, [isExpanded]);

	return (
		<m.div
			ref={elementRef}
			className={containerClasses}
			onClick={handleClick}
			whileHover='hover'
			style={{
				cursor: shouldExpand || (!shouldExpand && button1?.link) ? 'pointer' : 'initial',
				backgroundColor: !!solidBackgroundColor ? solidBackgroundColor : 'initial',
			}}
			{...(showVideo && { sourcesvideo: sourcesVideo })}
			{...rest}
		>
			<InteractiveCardMedia
				background={background}
				backgroundMobile={backgroundMobile}
				mediaClassName={mediaClassName}
				videoUrl={videoUrl}
				sourcesVideo={sourcesVideo}
				altText={imgAlt || title || text}
			>
				{children}
			</InteractiveCardMedia>

			{(shouldExpand || !hideTitleWhenClosed) && (
				<div className={cn(styles.gradientOverlay, { [styles['gradientOverlay--asHero']]: asHero })}></div>
			)}
			{shouldExpand ? (
				<InteractiveCardExpandedContent
					asHero={asHero}
					bgColor={bgColor}
					button1={button1}
					button2={button2}
					elementHeight={elementHeight}
					isExpanded={isExpanded}
					subtitle={subtitle}
					subheading={subheading}
					showTitle={showTitle}
					shouldDisplayTitle={shouldDisplayTitle}
					title={title}
					text={text}
					withBalancer={withBalancer}
				/>
			) : (
				<InteractiveCardCollapsedContent
					button1={button1}
					button2={button2}
					buttonClassName={buttonClassName}
					description={description}
					extraHeaderClassName={extraHeaderClassName}
					subheading={subheading}
					shouldDisplayTitle={shouldDisplayTitle}
					showTitle={showTitle}
					title={title}
					withBalancer={withBalancer}
				/>
			)}
			{shouldExpand && (
				<m.div
					className={cn(styles.expandBtn, { [styles['expandBtn--asHero']]: asHero })}
					animate={isExpanded ? 'rotateL' : 'rotateR'}
					initial={false}
					variants={rotateVariants}
				>
					{isExpanded ? <Minus /> : <Plus />}
				</m.div>
			)}
			{copyrightInfo && (
				<Detail className={cn(styles.copyrightInfo, { [styles['copyrightInfo--withExpandBtn']]: shouldExpand })}>
					{copyrightInfo}
				</Detail>
			)}
		</m.div>
	);
};

export default InteractiveCard;
