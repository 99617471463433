import { getCustomer } from '@services/shopify';
import { getCustomerToken } from '@services/shopify/customer-token';

type heapEventProperties = {
	experimentId?: string;
	variationId?: number;
};

type CustomHeapEvent = {
	experimentName: string;
	variationID?: any;
};

export const updateHeapEventProperties = async (propertiesObj?: heapEventProperties) => {
	const isHeapAvailable = typeof window !== 'undefined' && window.heap;
	if (!isHeapAvailable) {
		return;
	}

	// Check for login status
	const customerAccessToken = getCustomerToken();
	const customer = await getCustomer(customerAccessToken);

	window.heap.clearEventProperties();
	window.heap.addEventProperties({ 'Logged In': !!customer ? 'true' : 'false', ...propertiesObj });
};

type SignupEventLocation = 'Klaviyo Popup' | 'Homepage Module' | 'Attentive Popup';
export const sendSignupInfoToHeap = async (contact: string, location?: SignupEventLocation, isSMS?: boolean) => {
	const isHeapAvailable = typeof window !== 'undefined' && window.heap;
	if (!isHeapAvailable) {
		return;
	}

	isSMS
		? window.heap.track('SMS Signup', {
				'Submitted Phone': contact,
				'SMS Capture Form': location ?? '',
			})
		: window.heap.track('Email Signup', {
				'Submitted Email': contact,
				'Email Capture Form': location ?? '',
			});
};

export const handleKlaviyoPopupSubmission = () => {
	const emailInput = document.querySelector<HTMLInputElement>('form[data-testid*="klaviyo-form"] input[name="email"]');
	const phoneInput = document.querySelector<HTMLInputElement>('form[data-testid*="klaviyo-form"] input[name="phone-number"]');
	if (emailInput) {
		const email = emailInput.value;
		sendSignupInfoToHeap(email, 'Klaviyo Popup');
	} else if (phoneInput) {
		const phone = phoneInput.value;
		sendSignupInfoToHeap(phone, 'Klaviyo Popup', true);
	} else {
		console.warn('email or phone input not found!');
	}
};

export const handleKlaviyoPopupViewed = () => {
	const isHeapAvailable = typeof window !== 'undefined' && window.heap;

	if (!isHeapAvailable) {
		return;
	}

	window.heap.track('Pop up Viewed');
};

export const sendHeapEventFlag = (properties?: CustomHeapEvent) => {
	const isHeapAvailable = typeof window !== 'undefined' && window.heap;

	if (!isHeapAvailable) {
		return;
	}

	window.heap.track('A/B Test', properties);
};
