import { forwardRef, memo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useSearchBox } from 'react-instantsearch';
import { Close, Drawer, SearchIcon } from '@components';
import { ALGOLIA_SEARCH_PARAMS } from '@utils/algolia';
import { LOCALE_DICT } from '@constants';
import styles from './SearchInput.module.scss';
import '@algolia/autocomplete-theme-classic';

type SearchInputProps = {
	queryHook: (query: string, hook: (value: string) => void) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setOpen?: (status: boolean) => void;
	setQuery?: (query: string) => void;
	hidden?: boolean;
	story?: boolean;
	inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
	isOpen?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
	({ queryHook, hidden = false, story = false, inputProps, setQuery, setOpen, isOpen = false }, ref) => {
		const router = useRouter();
		const { locale } = router;
		const countryCode = LOCALE_DICT[locale].countryCode;
		const { query, refine, clear } = useSearchBox({ queryHook });
		const closeDrawerRef = useRef<HTMLDivElement>(null);

		if (typeof ref === 'function') {
			return null;
		}

		return hidden ? (
			<div className={styles.hidden}>
				<input
					type='search'
					onInput={e => {
						if (e.currentTarget.value === ' ') return;
						refine(e.currentTarget.value);
					}}
				/>
			</div>
		) : (
			<div className={styles.container}>
				<SearchIcon label='Search' />
				<input
					ref={ref}
					type='search'
					name='q'
					className={`aa-Input ${styles.searchInput}`}
					placeholder='Search'
					onInput={e => {
						if (e.currentTarget.value === ' ') return;
						refine(e.currentTarget.value);
					}}
					onKeyUp={e => {
						if (query === '' || query === ' ') return;
						if (e.key !== 'Enter') return;
						router.push(`/${ALGOLIA_SEARCH_PARAMS[countryCode].ALGOLIA_SEARCH_URL}${query}`);
					}}
					role='combobox'
					aria-expanded='false'
					aria-owns='predictive-search-results-list'
					aria-controls='predictive-search-results-list'
					aria-haspopup='listbox'
					aria-autocomplete='list'
					autoCorrect='off'
					autoComplete='off'
					autoCapitalize='none'
					spellCheck='false'
					form='predictive-search-form'
					maxLength={300}
					{...inputProps}
				/>
				<label style={{ display: 'none' }} htmlFor={inputProps.id}>
					Search
				</label>
				{!story && (
					<Drawer.Close asChild>
						<div ref={closeDrawerRef} style={{ visibility: 'hidden' }}></div>
					</Drawer.Close>
				)}
				<Close
					label='Close'
					onClick={() => {
						if (ref && ref.current) setQuery('');
						if (isOpen) setOpen(false);
						clear();

						if (!query.length) {
							closeDrawerRef.current && closeDrawerRef.current.click();
						}
					}}
				/>
			</div>
		);
	}
);

SearchInput.displayName = 'SearchInput';

export default memo(SearchInput);
