import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import { Detail } from '@components';
import styles from './SaveTag.module.scss';

const SaveTag = ({ label, detail }) => {
	return (
		<AccessibleIcon.Root label={label}>
			<>
				<svg aria-hidden='true' width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<g clipPath='url(#clip0_105_460)'>
						<path
							d='M42.2157 20.5321C42.6298 23.0583 42.905 25.6051 43.248 28.1661C43.248 28.274 43.3357 28.4662 43.1674 28.4655C34.9754 29.3729 33.8349 29.7142 25.7594 30.7032C26.3091 36.2672 30.1024 50.1207 28.4512 50.3C18.496 51.381 8.89152 53.6148 3.12384 53.9991C3.01696 54.0043 2.98816 53.9785 2.97152 53.8661C2.94592 53.3353 2.92288 52.8012 2.90752 52.2672C2.73024 48.9666 1.19424 38.4689 0.896 32.3028C0.8608 32.0091 1.33952 32.3381 1.24352 31.8786C0.88 25.8281 0.31168 19.795 0 13.7419C13.2077 12.0749 26.663 10.5698 40.0269 9.99976C40.1587 10.003 40.1837 10.0312 40.2029 10.1354C40.4403 11.3075 40.6509 12.4855 40.9069 13.6538C40.9222 13.7239 41.0643 14.1757 41.0675 14.2039C41.1987 15.425 41.6691 16.7745 41.8477 17.9898C41.92 18.6112 42.1613 20.4363 42.2157 20.5321Z'
							fill='#AAD6EF'
						/>
						<path
							d='M55.0611 18.4351C39.872 18.5733 24.2918 17.1389 9.16224 16.1248C8.36992 23.8269 8.07936 33.2995 7.488 41.1173C7.47968 41.2645 12.2432 41.4913 12.2739 41.6378C21.1251 41.5691 30.016 42.4797 38.784 43.8068C43.4406 44.0298 52.8774 44.2759 57.5546 44.3157L60.5254 44.4488C61.9219 44.3511 61.9891 44.4758 63.0854 44.4713C63.1418 44.4713 63.1718 44.4417 63.1789 44.3761C63.2493 43.6647 63.3069 42.952 63.3619 42.2394C63.6358 34.6902 64.4442 27.0678 63.6986 19.5334C63.6774 19.4402 63.6915 19.2873 63.5853 19.259C60.7597 18.752 57.9315 18.4011 55.0611 18.4351Z'
							fill='#00AE4F'
						/>
					</g>
					<defs>
						<clipPath id='clip0_105_460'>
							<rect width='64' height='44' fill='white' transform='translate(0 9.99976)' />
						</clipPath>
					</defs>
				</svg>
				<Detail className={styles.detail}>{detail}</Detail>
			</>
		</AccessibleIcon.Root>
	);
};

export default SaveTag;
