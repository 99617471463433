import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';

function GlobalStyleInjector() {
	const isQuickAdd = useFeatureIsOn('is-quick-add');
	const { pathname } = useRouter();
	const showQuickAdd = isQuickAdd && pathname.includes('/eyeglasses');

	return (
		<style jsx global>
			{`
				:root {
					--pdp-left-rail: ${showQuickAdd ? '60%' : '66.7%'};
					--pdp-right-rail: ${showQuickAdd ? '40%' : '33.3%'};
				}
			`}
		</style>
	);
}

export default GlobalStyleInjector;
