import { useRef } from 'react';
import { Button, FavoriteIcon, Flex, LightBox } from '@components';
import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import { TOP_FRAMES_PATH, ACCESSORIES_PATH } from '@constants';
import { useCartContext } from '@context';
import { useDiscountedPrice, useIsProductVariantSoldOut } from '@utils/hooks';
import { ButtonGroupProps } from './ButtonGroup';

type SideBySideButtonProps = {
	collectionPath: string;
	price: { amount: number; currencyCode: string };
} & Pick<
	ButtonGroupProps,
	| 'secondaryAction'
	| 'compact'
	| 'variant'
	| 'product'
	| 'isMobile'
	| 'dataTags'
	| 'primaryAction'
	| 'primaryActionData'
	| 'parentCollectionHandle'
	| 'bundleKey'
	| 'openMinicartOnPurchase'
	| 'linkToProduct'
	| 'productClickEvent'
	| 'alreadyInCart'
	| 'label'
>;

const SideBySideButton = ({
	secondaryAction,
	compact,
	product,
	collectionPath,
	variant,
	isMobile,
	dataTags,
	primaryAction,
	primaryActionData,
	parentCollectionHandle,
	bundleKey,
	openMinicartOnPurchase,
	linkToProduct,
	alreadyInCart,
	productClickEvent,
	price,
	label,
}: SideBySideButtonProps) => {
	const { handleCartAdd, isCartMutating } = useCartContext();
	const buttonRef = useRef<HTMLButtonElement>(null);
	const compareAtPrice = variant.compareAtPrice?.amount ?? null;
	const collectionPathValue = parentCollectionHandle ?? collectionPath ?? '';
	const { checkIfSoldOut } = useIsProductVariantSoldOut();
	const isSoldOut = checkIfSoldOut(product.handle, variant.option);
	const [newPrice, newCompareAtPrice] = useDiscountedPrice({
		price: price.amount,
		compareAtPrice: compareAtPrice,
		type: product.type,
	});

	return (
		<Flex align='start' gap={compact ? 2 : 3} fullWidth>
			{secondaryAction === 'favorite' && !isSoldOut && (
				<FavoriteIcon product={product} variant={variant} dataTag={dataTags.favorite} />
			)}
			{secondaryAction === 'zoom' && isMobile && (
				<LightBox shape='square' variant={variant} height={26} width={26} dataTag={dataTags.zoom} />
			)}
			{primaryAction === 'cart' && (
				<Button
					onClick={async () => {
						handleCartAdd(
							[
								{
									variant: { ...variant, collection: parentCollectionHandle ?? '' },
									...(bundleKey && {
										customAttributes: [{ key: '_bundle_key', value: bundleKey }],
									}),
									customAttributes: [
										{
											key: CART_LINE_ATTRIBUTE_KEYS.COLLECTION_PATH,
											value: collectionPathValue.includes(ACCESSORIES_PATH)
												? `${ACCESSORIES_PATH}/`
												: `${TOP_FRAMES_PATH}/${collectionPathValue}`,
										},
									],
								},
							],
							openMinicartOnPurchase,
							buttonRef
						);
					}}
					disabled={isCartMutating}
					color='white'
					size='small'
					withPrice={!alreadyInCart && !isSoldOut}
					price={newPrice}
					currencyCode={price.currencyCode}
					compareAtPrice={newCompareAtPrice}
					fullWidth
					ref={buttonRef}
					{...(product?.name ? { 'data-add-to-cart': product.name } : { 'data-add-to-cart': true })}
					{...dataTags.button}
					{...(alreadyInCart && { disabled: true, label: 'In Cart!' })}
					{...(isSoldOut && { disabled: true, label: 'Sold out!' })}
				/>
			)}
			{primaryAction === 'view' && (
				<Button
					href={linkToProduct}
					onClick={productClickEvent}
					color='white'
					size='small'
					label={label}
					fullWidth
					data-view-product={product.name}
					{...dataTags.button}
				/>
			)}
			{primaryAction === 'shop' && (
				<Button
					href={linkToProduct}
					onClick={productClickEvent}
					color='green'
					label={label}
					fullWidth
					{...dataTags.button}
				/>
			)}
			{primaryAction === 'custom' && primaryActionData.customComponent}
		</Flex>
	);
};

export default SideBySideButton;
