import { forwardRef, SVGAttributes } from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import cn from 'classnames';
import variables from '@styles/export.module.scss';
import styles from './HeartIcon.module.scss';

type HeartIconProps = {
	label: string;
	width?: number;
	height?: number;
	active?: boolean;
	isBouncing?: boolean;
	viewBox?: SVGAttributes<SVGSVGElement>['viewBox'];
	pathFill?: SVGAttributes<SVGPathElement>['fill'];
	pathColor?: SVGAttributes<SVGPathElement>['stroke'];
};

const HeartIcon = forwardRef<SVGSVGElement, HeartIconProps>(
	(
		{ label, width = 18, height = 18, active, isBouncing, pathColor = variables.gray3, pathFill = 'white' }: HeartIconProps,
		ref
	) => {
		const classes = cn(styles.container, {
			[styles.active]: active,
			[styles.bounce]: isBouncing,
		});

		return (
			<AccessibleIcon.Root label={label}>
				<svg
					className={classes}
					ref={ref}
					width={width}
					height={height}
					viewBox={`0 0 ${width} ${height - 2}`}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.99452 2.66119C7.39504 0.8432 4.7278 0.354166 2.72376 2.0189C0.719715 3.68363 0.437574 6.46698 2.01136 8.43587L8.99452 15L15.9777 8.43587C17.5515 6.46698 17.3038 3.66612 15.2653 2.0189C13.2268 0.371678 10.594 0.8432 8.99452 2.66119Z'
						fill={pathFill}
						stroke={pathColor}
						strokeWidth='1.5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</AccessibleIcon.Root>
		);
	}
);

HeartIcon.displayName = 'HeartIcon';
export default HeartIcon;
