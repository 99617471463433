import Link from 'next/link';
import { BYOB_WIDGET_COPY } from '@constants';
import { Button, Container, Heading, Img, Paragraph } from '@components';
import styles from './BYOBWidget.module.scss';

const BYOBWidget = () => {
	return (
		<Container className={styles.container}>
			<Link href={BYOB_WIDGET_COPY.CTA_LINK} role='none' data-nav-byob-imageblock>
				<Heading tag='h4' className={styles.heading}>
					{BYOB_WIDGET_COPY.TITLE}
				</Heading>
				<Paragraph style={{ textAlign: 'center' }}>{BYOB_WIDGET_COPY.BODY}</Paragraph>
				<Button color='green'>{BYOB_WIDGET_COPY.CTA_TEXT}</Button>
				<Img
					src={BYOB_WIDGET_COPY.IMAGE_URL}
					alt={BYOB_WIDGET_COPY.CTA_TEXT}
					aspectRatio={'4/3'}
					height={455}
					width={254}
				/>
			</Link>
		</Container>
	);
};

export default BYOBWidget;
