import { MutableRefObject, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { AnimatePresence, m } from 'framer-motion';
import { Root as PortalRoot } from '@radix-ui/react-portal';
import { useRouter } from 'next/router';
import { fadeLinear, slideFromPaddedBottom } from '@utils/motions';
import { Button, Flex, Heading, Paragraph, TypographyButton } from '@components';
import variables from '@styles/export.module.scss';
import { formatCurrency } from '@utils/shopify';
import { BADGE_TYPES, LOCALE_CODES, LOCALE_DICT, MEDIUM_WIDTH, PRODUCT_TYPES } from '@constants';
import { NormalizedVariant } from '@ts/product';
import { useIsMobile } from '@utils/hooks';
import KlarnaPlacement from '../../cart/KlarnaPlacement';
import styles from './ProductViewCTA.module.scss';

type FloatingCTAProps = {
	ctaRef: MutableRefObject<HTMLButtonElement>;
	productType?: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];
	selectedVariant?: NormalizedVariant;
	buttonProps: Parameters<typeof Button>[0] & {
		hasSubtotal?: boolean;
		isLoading?: boolean;
		availableForSale?: boolean;
		onClick?: (ref?: React.MutableRefObject<HTMLButtonElement | null>) => void;
	};
	name: string;
	subtotal?: number | string;
	minDigits?: number;
	maxDigits?: number;
	tag?: typeof BADGE_TYPES.TOP_RATED;
};

function FloatingCTA({
	ctaRef,
	selectedVariant,
	productType,
	buttonProps,
	name,
	subtotal,
	minDigits = 0,
	maxDigits = 0,
}: FloatingCTAProps) {
	const classes = cn(styles['cta'], buttonProps.extraClasses);
	const [isButtonSticky, setIsButtonSticky] = useState(true);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const { locale } = useRouter();
	const currencyCode = selectedVariant?.price?.currencyCode ?? LOCALE_DICT[locale].currencyCode;
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const isBaseFrame = productType?.includes(PRODUCT_TYPES.BASE_FRAME);
	const compareAtPrice = selectedVariant?.compareAtPrice?.amount ?? null;
	const price = selectedVariant?.price?.amount || subtotal || null;
	const isPriceString = typeof price === 'string';
	const floatingCta = useRef<HTMLButtonElement>(null);

	const heading = isBaseFrame ? (
		<TypographyButton className={styles['product-title']} small>
			{formatCurrency(
				{
					amount: subtotal,
					locale,
					currencyCode,
					minDigits,
					maxDigits,
				},
				showCurr
			)}
		</TypographyButton>
	) : (
		<Heading className={styles['product-title']} tag='h6'>
			{name}
		</Heading>
	);

	useEffect(() => {
		const button = ctaRef?.current;
		if (!button) return;
		const observerOptions = {
			root: null,
			rootMargin: '0px',
			threshold: 0,
		};

		const handleIntersection = entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					setIsButtonSticky(false);
				} else {
					setIsButtonSticky(true);
				}
			});
		};
		const observer = new IntersectionObserver(handleIntersection, observerOptions);
		observer.observe(button);

		return () => {
			observer.unobserve(button);
		};
	}, []);

	return (
		<PortalRoot {...(typeof document !== 'undefined' && { container: document.getElementById('__next') })}>
			<AnimatePresence>
				{isButtonSticky && (
					<>
						<m.div className={styles.stickyButton} data-floating-cta={buttonProps.label} {...slideFromPaddedBottom}>
							<Flex
								column
								justify={locale === LOCALE_CODES.US ? 'normal' : 'center'}
								style={isBaseFrame ? { width: '50%' } : { maxWidth: '40%' }}
							>
								{heading}
								{isBaseFrame ? (
									<KlarnaPlacement subtotal={subtotal as number} />
								) : (
									<Flex gap={2}>
										<Paragraph>
											{!compareAtPrice && isBaseFrame && (
												<span style={{ color: variables.gray4 }}>{`Starting at `}</span>
											)}
											<span
												style={{
													color: compareAtPrice ? variables.red1 : variables.gray4,
													fontSize: compareAtPrice && '1.2rem',
												}}
											>
												{isPriceString
													? price
													: formatCurrency(
															{ amount: price, locale, currencyCode, minDigits, maxDigits },
															showCurr
														)}
											</span>
										</Paragraph>
										{compareAtPrice && (
											<Paragraph
												style={{
													color: variables.gray3,
													textDecoration: 'line-through',
													fontSize: '0.8rem',
													alignContent: 'space-evenly',
												}}
											>
												{`${formatCurrency({ amount: compareAtPrice, locale, currencyCode }, showCurr)}`}
											</Paragraph>
										)}
									</Flex>
								)}
							</Flex>
							<Button
								extraClasses={classes}
								size={isMobile ? 'medium' : 'large'}
								color='green'
								onClick={() => buttonProps.onClick(floatingCta)}
								withChevron={buttonProps.withChevron}
								chevronDirection='right'
								withPrice={buttonProps?.hasSubtotal}
								price={buttonProps.price}
								label={buttonProps.label}
								disabled={buttonProps.disabled || !buttonProps.availableForSale}
								currencyCode={currencyCode}
								ref={floatingCta}
								{...buttonProps.dataTags}
							/>
						</m.div>
						<m.div className={styles.stickyGradient} {...fadeLinear} />
					</>
				)}
			</AnimatePresence>
		</PortalRoot>
	);
}

export default FloatingCTA;
