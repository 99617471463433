import { useMemo } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useCartContext } from '@context';
import { PRODUCT_TYPES } from '..';

interface UseIsDiscountEligibleProps {
	compareAtPrice?: number;
	type?: string;
	mandatoryDiscount?: boolean;
}

const useIsDiscountEligible = ({ compareAtPrice, type = '', mandatoryDiscount = false }: UseIsDiscountEligibleProps) => {
	const { applySubscriptionDiscount } = useCartContext();
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');

	const isDiscountEligible = useMemo(() => {
		if (compareAtPrice) return false;
		if (!isV2Subscription) return false;
		if (!applySubscriptionDiscount && !mandatoryDiscount) return false;
		const validProductTypes = [PRODUCT_TYPES.TOP_FRAME, PRODUCT_TYPES.BASE_FRAME_SR];
		if (!validProductTypes.some(productType => type.includes(productType))) return false;
		return true;
	}, [compareAtPrice, type, mandatoryDiscount, applySubscriptionDiscount, isV2Subscription]);

	return isDiscountEligible;
};

export default useIsDiscountEligible;
