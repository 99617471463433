import { FC } from 'react';
import { useBFContext } from '@context';
import Lens from '../Lens/Lens';

const Lenses: FC = () => {
	const { rxType, rxOptions } = useBFContext();
	const currentLensOptions = rxOptions.find(option => option.optionValue === rxType).lenses;

	return (
		<ul>
			{currentLensOptions.map((lens, index) => (
				<Lens key={`${lens.optionValue}-lens-option`} lens={lens} index={index} />
			))}
		</ul>
	);
};

export default Lenses;
