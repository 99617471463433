import cn from 'classnames';
import { AllTopsWidget, Container, LabelText, Link, Title } from '@components';
import BYOBWidget from '../BYOBWidget';
import LensesWidget from '../LensesWidget';
import styles from './AllTopsMenu.module.scss';

const AllTopsMenu = ({ menuGroup }) => {
	const isLensesMenu = menuGroup.handle === 'lenses'; // visibility handled in MenuGroup.tsx
	const showBYOBWidget = menuGroup.showWidget && menuGroup.widget === 'BYOB' && !isLensesMenu;
	const showAllTopsWidget = menuGroup.showWidget && menuGroup.widget === 'All Tops' && !isLensesMenu;

	return (
		<Container className={cn(styles.container, { [styles['lenses-container']]: isLensesMenu })}>
			{showAllTopsWidget && <AllTopsWidget />}
			{menuGroup.subMenuGroup.map(({ handle, subMenuGroup, text }) => {
				return (
					<ul key={handle ?? `subnav-container-${handle}`}>
						<li data-handle={handle} key={`submenu-group-${handle}`}>
							<Title>{text}</Title>

							<ul style={{ marginLeft: '-0.3rem' }}>
								{subMenuGroup.map((subSubNavItem, index) => {
									const isBYOBMenuL = subSubNavItem.handle === 'build-your-own-bundle';

									return (
										<li
											data-handle={subSubNavItem.handle}
											key={`sub-sub-menu-group-${handle + index}`}
											className={styles['list-item']}
											{...(isBYOBMenuL ? { 'data-nav-byob-link': true } : {})}
										>
											{subSubNavItem.collectionTag ? (
												<>
													<div data-type={subSubNavItem.collectionTag} className={styles['item-tag']} />
													<LabelText
														data-type={subSubNavItem.collectionTag}
														className={styles['item-tag-label']}
													>
														{subSubNavItem.collectionTag}
													</LabelText>
												</>
											) : null}
											<Link href={subSubNavItem.link ?? '#'} small>
												{subSubNavItem.text}
											</Link>
										</li>
									);
								})}
							</ul>
						</li>
					</ul>
				);
			})}
			{showBYOBWidget && <BYOBWidget />}
			{isLensesMenu && <LensesWidget />}
		</Container>
	);
};

export default AllTopsMenu;
