import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const PairLogo = () => {
	return (
		<AccessibleIcon.Root label='pair logo b/w'>
			<svg width='54' height='28' viewBox='0 0 54 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g clipPath='url(#clip0_3201:24026)'>
					<path
						d='M9.2038 7.17725C7.3259 7.17725 5.77445 7.9788 4.98498 9.31609H4.7135V7.17725C3.22367 7.64018 1.52648 7.71625 -0.0649414 7.72034V28.1771H4.98498V19.8483H5.2573C6.07425 20.784 7.54409 21.3451 9.12219 21.3451C12.8788 21.3451 15.6003 18.3777 15.6003 14.2612C15.6003 10.1446 12.8788 7.17725 9.2038 7.17725ZM7.27177 20.2761C5.63871 20.2761 4.98498 18.6992 4.98498 16.2683V12.6573C4.98498 10.0105 5.66619 8.38039 7.3259 8.38039C9.3945 8.38039 10.1573 10.7859 10.1573 14.2612C10.1573 17.7365 9.36786 20.2761 7.27177 20.2761Z'
						fill='black'
					/>
					<path
						d='M39.7643 7.17725C38.2095 7.66063 36.3216 7.72361 34.5928 7.72197V21.0785H39.7643V7.17725Z'
						fill='black'
					/>
					<path
						d='M47.7196 12.7914C47.7196 12.1232 48.1277 11.7216 48.8081 11.7216H53.435L53.9796 7.17725L51.8019 7.31056C49.7608 7.4447 48.2093 9.02164 47.7213 11.4541H47.449V7.17725C45.9583 7.64673 44.2545 7.7187 42.5498 7.72116V21.0785H47.7246L47.7196 12.7914Z'
						fill='black'
					/>
					<path
						d='M24.8117 7.17725C20.239 7.17725 17.953 8.70102 17.6807 12.1763L17.6116 13.085L22.9338 12.0594V11.4549C22.9338 8.70183 23.4243 7.71298 24.8117 7.71298C25.9551 7.71298 26.7446 8.59469 26.7446 10.9208V13.5938L22.689 14.3176C18.6084 15.0398 16.9429 16.3763 16.9429 18.489C16.9429 20.227 18.6842 21.3517 21.434 21.3517C24.128 21.3517 26.0067 20.3898 26.7413 18.6787H27.0136V21.085H31.8053V11.9555C31.8087 8.5816 29.1746 7.17725 24.8117 7.17725ZM26.7446 14.9834C26.7446 18.2174 25.8194 20.0087 24.1314 20.0087C23.0696 20.0087 22.3892 19.1269 22.3892 17.7635C22.3892 16.2912 23.6692 14.6358 26.7446 14.1279V14.9834Z'
						fill='black'
					/>
					<path
						d='M39.7423 0.194092C39.7423 0.194092 37.8969 0.44928 34.5059 0.814887V5.28151C37.6496 5.32976 39.7423 4.66071 39.7423 4.66071V0.194092Z'
						fill='black'
					/>
				</g>
				<defs>
					<clipPath id='clip0_3201:24026'>
						<rect width='54' height='28' fill='white' />
					</clipPath>
				</defs>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default PairLogo;
