import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
	BASE_FRAME_NAMES,
	DEFAULT_DEMOS,
	findVisibleVariants,
	FRAME_COLORS,
	getBasePLPProps,
	getShopifyIdFromBase64,
	groupBy,
	isEmpty,
	NEW_COLORS,
	PRODUCT_TYPES,
} from '@utils/index';
import { normalizeSingleCollection } from '@utils/normalizers';
import { NormalizedCollection, NormalizedProduct, NormalizedVariant } from '@ts/product';
import { Button, Container, Flex, Grid, ProductGrid, VerticalCard } from '@components';
import { useLocalStorage } from '@utils/hooks';
import { getMultipleCollections } from '@services/shopify/operations/get-multiple-collections';
import { ComponentProductList } from '@ts/index';
import styles from './ProductList.module.scss';

const ProductList = ({ collectionHandle, limit, overrideWithSunBaseFrames = false }: ComponentProductList) => {
	const { locale } = useRouter();
	const isHideNewColors = useFeatureIsOn('is-hide-new-products');
	const [activeFrame] = useLocalStorage<(typeof BASE_FRAME_NAMES)[number]>('activeFrame', 'Larkin');
	const [handle, setHandle] = useState('');
	const [variants, setVariants] = useState<{ [key: string]: Array<NormalizedVariant> }>({});
	const [hasAvailableProducts, setHasAvailableProducts] = useState(null);
	const [collectionProductType, setCollectionProductType] = useState<'base-frames' | 'top-frames'>(null);
	const [collection, setCollection] = useState<NormalizedCollection>(null);

	useEffect(() => {
		const fetchSunBaseFramesCollection = async () => {
			const demo = 'all' as (typeof DEFAULT_DEMOS)[number];
			const plpProps = await getBasePLPProps({ category: 'sunglasses', demo, locale });
			const { collection } = plpProps;
			if (limit) {
				collection.products = collection.products.slice(0, Number(limit));
			}
			return collection;
		};

		const fetchShopifyCollection = async () => {
			const shopifyCollectionId = getShopifyIdFromBase64(collectionHandle);
			const collectionData = await getMultipleCollections(`(id:${shopifyCollectionId})`);
			const collection = normalizeSingleCollection(collectionData[0]);

			if (isHideNewColors) {
				const filteredData = collection.products
					.map(product => ({
						...product,
						variants: product.variants.filter(v => !NEW_COLORS.includes(v.option as FRAME_COLORS)),
					}))
					.filter(product => product.variants.length > 0);

				collection.products = filteredData;
			}

			return collection;
		};

		const setBaseFrameData = _collection => {
			if (limit) {
				_collection.products = _collection.products.slice(0, Number(limit));
			}
			setCollection(_collection);
			setVariants({ UNUSED: _collection.products[0].variants });
			setCollectionProductType('base-frames');
		};

		const setTopFrameData = _collection => {
			const variants = findVisibleVariants(_collection, activeFrame);
			const limitedVariants = limit ? variants.slice(0, Number(limit)) : variants;
			setHandle(_collection.handle);
			setVariants(groupBy(limitedVariants, ''));
			setHasAvailableProducts(limitedVariants.length > 0);
			setCollectionProductType('top-frames');
		};

		const fetchCollectionStrategies = {
			SunBaseFrames: fetchSunBaseFramesCollection,
			Shopify: fetchShopifyCollection,
		};

		const handleFrameDataStrategies = {
			baseFrame: setBaseFrameData,
			topFrame: setTopFrameData,
		};

		(async () => {
			try {
				const collectionToFetch = overrideWithSunBaseFrames ? 'SunBaseFrames' : 'Shopify';
				const _collection = await fetchCollectionStrategies[collectionToFetch]();
				const frameType = _collection.products[0].type.includes(PRODUCT_TYPES.BASE_FRAME) ? 'baseFrame' : 'topFrame';
				handleFrameDataStrategies[frameType](_collection);
			} catch (error) {
				console.error('Error fetching collection data:', error);
			}
		})();
	}, [activeFrame, collectionHandle, limit, locale, overrideWithSunBaseFrames]);

	if (isEmpty(variants)) {
		return <SkeletonProductList limit={limit} />;
	}

	return collectionProductType === 'top-frames' ? (
		<>
			<Grid>
				{hasAvailableProducts &&
					Object.entries(variants).map(([key, variants]) => {
						return (
							<ProductGrid key={key} type='tops'>
								{variants.map(variant => (
									<VerticalCard
										key={variant.handle}
										product={variant.product as NormalizedProduct}
										variant={variant}
									/>
								))}
							</ProductGrid>
						);
					})}
			</Grid>
			{limit && <Button href={`/top-frames/${handle}`} color='white' label='View More' padding='1rem 3.2rem' />}
		</>
	) : (
		<Grid>
			{
				<ProductGrid type='base' style={{ width: '100%' }}>
					{collection.products.map(p => (
						<VerticalCard
							key={p.handle}
							product={p}
							variant={p.variants[0]}
							primaryAction='view'
							showVariantControls
							showLensController={!!overrideWithSunBaseFrames}
						/>
					))}
				</ProductGrid>
			}
		</Grid>
	);
};

const SkeletonButton = () => {
	return (
		<Flex justify='center' fullWidth className={styles['skeleton-button-container']}>
			<Container className={styles['skeleton-button']} />
		</Flex>
	);
};

export const SkeletonProductList = ({ limit }) => {
	const defaultLimit = 15;
	return (
		<>
			<Container className={styles['skeleton-product-list']}>
				{Array(limit ?? defaultLimit)
					.fill(true)
					.map((_, index) => (
						<SkeletonTopFrame key={index} />
					))}
			</Container>
			{limit && <SkeletonButton />}
		</>
	);
};

const SkeletonTopFrame = () => {
	return <Container className={styles['skeleton-top-frame']} />;
};

export default ProductList;
