import { GetStaticPathsContext, GetStaticPaths } from 'next';
import { Dict } from '@ts/general';
import { LOCALE_CODES } from '@constants';
import { en, es } from '../public/locales';

export const withLocalizedPaths = (fn: GetStaticPaths) => {
	return async (context: GetStaticPathsContext) => {
		try {
			const { locales } = context;
			const { paths, fallback } = await fn(context);
			const localizedPaths = [];

			paths.forEach(path => {
				if (typeof path === 'string') {
					return;
				}
				return locales.forEach(locale => {
					localizedPaths.push({
						params: path.params,
						locale,
					});
				});
			});

			return {
				paths: localizedPaths,
				fallback,
			};
		} catch (error) {
			console.error(error);
		}
	};
};

type Translate = (key: string, locale: string, args?: Dict) => string;

export const translate: Translate = (key, locale, args = {}) => {
	const translations = {
		[LOCALE_CODES.MX]: es,
		[LOCALE_CODES.US]: en,
	};

	const translationDict = translations[locale] || en;
	const translation = translationDict[key] || key;

	return typeof translation === 'function' ? translation(args) : translation;
};
