import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Body, Button, Caption, Flex, Heading, Input, Paragraph } from '@components';
import { trackNewsletterSignup } from '@services/analytics/trackers';
import { sendSignupInfoToHeap } from '@services/analytics/heap';
import styles from './Newsletter.module.scss';

export type CustomerCreateNewsletterInput = {
	email: string;
};

interface NewsLetterFormProps {
	klaviyoListId: string;
	header: string;
	additionalInfo?: string;
	page?: string;
	subheader?: string;
	backgroundImage?;
	className?: string;
}

const NewsLetter = ({
	klaviyoListId,
	header,
	additionalInfo,
	page,
	subheader,
	backgroundImage,
	className,
}: NewsLetterFormProps): JSX.Element => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const [message, setMessage] = useState(null);

	const onSubmit: SubmitHandler<CustomerCreateNewsletterInput> = async ({ email }): Promise<void> => {
		try {
			await fetch('api/suscribe', {
				method: 'POST',
				headers: { 'accept': 'application/json', 'content-type': 'application/json' },
				body: JSON.stringify({ klaviyoListId, email }),
			});

			trackNewsletterSignup(email);
			sendSignupInfoToHeap(email, 'Homepage Module');
			setMessage('Thanks for subscribing! Please check your email to confirm your subscription.');
		} catch (error) {
			console.error('Error during subscription:', error);
			setMessage('An error occurred. Please try again later.');
		}
		reset();
	};

	const content = (
		<>
			<Flex column>
				<Heading tag='h3'>{header}</Heading>
				<Body>{subheader}</Body>
			</Flex>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				{!message ? (
					<>
						<Flex gap={3}>
							<Input
								required
								className={styles.emailInputContainer}
								name='email'
								type='email'
								placeholder={'Your Email'}
								{...register('email', {
									required: 'Email is required',
									maxLength: 80,
									pattern: {
										value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
										message: 'Invalid email address',
									},
								})}
								placeholderEffect={false}
								id='newsletter-email'
							/>
							<Button color={'green'} removeEffects type='submit' label={'Submit'} style={{ height: '5rem' }} />
						</Flex>
						{errors.email && errors.email.message && (
							<Paragraph className={styles.errorMessage}>
								{typeof errors.email.message === 'string' ? errors.email.message : 'An error occurred'}
							</Paragraph>
						)}
					</>
				) : (
					<div className={styles.successMessage}>
						<Paragraph>{message}</Paragraph>
					</div>
				)}
			</form>
		</>
	);

	return (
		<Flex
			gap={4}
			column
			className={`${styles.container} ${styles.isHPRedesign} ${className}`}
			data-newsletter-signup
			data-component-type='newsletter'
			data-page-type={page}
			align='center'
			style={{
				backgroundImage: !!backgroundImage ? `url(${backgroundImage.url})` : null,
				backgroundPosition: 'center',
				backgroundSize: 'cover',
			}}
		>
			<Flex className={styles.content}>{content}</Flex>
			{additionalInfo ? (
				<div className={styles.additionalInfo}>
					<Caption small style={{ textAlign: 'justify' }}>
						{additionalInfo}
					</Caption>
				</div>
			) : null}
		</Flex>
	);
};

export default NewsLetter;
