import { useRef, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NavigationOptions } from 'swiper/types';
import {
	Caption,
	Chevron,
	ConditionalLink,
	FacebookIcon,
	Flex,
	Heading,
	InstagramIcon,
	Spacer,
	Testimonial,
	TikTokIcon,
	YouTubeIcon,
} from '@components';
import { useContentfulAsset } from '@services/contentful/hooks';
import { useIsMobile } from '@utils/hooks';
import { InteractiveCardEntrySkeletonType } from '@ts/contentful';
import { MEDIUM_MAX_WIDTH } from '@constants';
import styles from './Testimonials.module.scss';

const Testimonials = ({ heading, subheading, testimonialSlide, page, unoptimized, slug, customer }) => {
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const [isBeginning, setIsBeginning] = useState(false);
	const [isEnd, setIsEnd] = useState(false);
	const [isMuted, setIsMuted] = useState(true);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_MAX_WIDTH });

	const navigationRefs = {
		prevEl: navigationPrevRef.current,
		nextEl: navigationNextRef.current,
	};

	const { data } = useContentfulAsset<InteractiveCardEntrySkeletonType>({
		id: `testimonialSlider-${slug}`,
		query: {
			'content_type': 'testimonialSlider',
			'fields.slug': slug,
		},
	});

	if (!!data) {
		testimonialSlide = data[0]?.fields?.testimonialSlide ?? [];
	}

	if (!testimonialSlide.length) return null;
	if (!customer && String(slug).includes('logged-in')) return null;
	if (customer && String(slug).includes('logged-out')) return null;

	return (
		<div className={styles.container} data-testimonial-slider data-component-type='testimonials' data-page-type={page}>
			<Flex column align='start' className={styles.copy}>
				<Heading data-testid='testimonials-heading' tag={isMobile ? 'h4' : 'h3'} fixedSize>
					{heading}
				</Heading>
				<Caption data-testid='testimonials-caption' style={{ fontWeight: '400' }}>
					{subheading}
				</Caption>
				<Flex className={styles.socialContainer}>
					<ul data-testid='social-icons' className={styles.socialList}>
						<li>
							<a href='https://www.facebook.com/paireyewear/'>
								<FacebookIcon type='round' />
							</a>
						</li>
						<li>
							<a href='https://www.tiktok.com/@paireyewear/'>
								<TikTokIcon />
							</a>
						</li>
						<li>
							<a href='https://www.instagram.com/paireyewear/'>
								<InstagramIcon />
							</a>
						</li>
						<li>
							<a href='https://www.youtube.com/channel/UCsfEDry8WF9MRqYaCkQRipw'>
								<YouTubeIcon />
							</a>
						</li>
					</ul>
				</Flex>
			</Flex>
			<Swiper
				className={styles.swiper}
				data-testid='testimonials'
				direction='horizontal'
				spaceBetween={8}
				slidesPerView='auto'
				speed={500}
				modules={[Navigation]}
				navigation={navigationRefs}
				onSlideChange={swiper => {
					setIsBeginning(swiper.isBeginning);
					setIsEnd(swiper.isEnd);
				}}
				onSlidesGridLengthChange={swiper => {
					setIsBeginning(swiper.isBeginning);
					setIsEnd(swiper.isEnd);
					swiper.on('reachEnd', () => {
						setIsEnd(true);
					});
				}}
				onBeforeInit={swiper => {
					(swiper.params.navigation as NavigationOptions).prevEl = navigationPrevRef.current;
					(swiper.params.navigation as NavigationOptions).nextEl = navigationNextRef.current;
				}}
			>
				<ul>
					{testimonialSlide.map((testimonial, index) => (
						<SwiperSlide key={`${testimonial.text}-${index}`} className={styles['container__swiper-slide']}>
							<ConditionalLink
								href={testimonial.featuredProductUrl}
								condition={!!testimonial.featuredProductUrl}
								reduceOpacityOnHover
							>
								<Testimonial
									unoptimized={!!unoptimized}
									{...testimonial}
									isMuted={isMuted}
									setIsMuted={setIsMuted}
								/>
							</ConditionalLink>
						</SwiperSlide>
					))}
				</ul>
			</Swiper>
			<div className={styles.navigationButtonsContainer}>
				<div className={`${styles.prevButton} ${isBeginning ? styles.disable : ''}`} ref={navigationPrevRef}>
					<Chevron extraClasses={styles.chevron} direction='left' />
				</div>
				<div className={`${styles.nextButton} ${isEnd ? styles.disable : ''}`} ref={navigationNextRef}>
					<Chevron extraClasses={styles.chevron} direction='right' />
				</div>
			</div>
			<Spacer size='3.2rem' />
			<div className={styles.divider}></div>
		</div>
	);
};

export default Testimonials;
