import { memo } from 'react';
import cn from 'classnames';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { BUILD_FLOW_STEPS, MEDIUM_LARGE_WIDTH } from '@constants';
import { useIsMobile } from '@utils/hooks';
import { useBFContext } from '@context';
import { Flex, Heading } from '@components';
import styles from './ProductView.module.scss';

const ProductView = () => {
	const { product, variantImages, step, isSubscriptionActivated, skipSubscription } = useBFContext();
	const isTablet = useIsMobile({ maxWidth: MEDIUM_LARGE_WIDTH });
	const name = product.name;
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');

	const isTopFrameStep = step === BUILD_FLOW_STEPS.TOP_FRAMES;
	const showProductViewActions =
		!isSubscriptionActivated ||
		(isSubscriptionActivated && skipSubscription) ||
		!isTopFrameStep ||
		!isTablet ||
		isV2Subscription;

	return !isTablet ? (
		<Flex
			column
			align='center'
			justify='between'
			className={cn(styles['product-view'], {
				[styles['--no-preview']]: isTablet,
			})}
			data-use-padding={!isTopFrameStep}
			data-use-height-tops-step={isTopFrameStep}
		>
			<Flex
				column
				fullWidth
				align='center'
				className={cn({
					[styles['no-preview']]: isTablet,
				})}
			>
				{showProductViewActions && (
					<Flex fullWidth justify='center' className={styles['preview-header']}>
						<Heading tag={isTablet ? 'h6' : 'h3'} style={{ marginTop: isTablet ? '0' : '1rem' }}>
							{!isTablet && name}
						</Heading>
					</Flex>
				)}
				<div className={styles['image-container']}>
					<img
						className={styles['product-image']}
						src={variantImages[0].url}
						alt={'product'}
						width={variantImages[0].width}
						height={variantImages[0].height}
					/>
				</div>
			</Flex>
		</Flex>
	) : null;
};

export default memo(ProductView);
